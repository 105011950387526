import React, { Component } from 'react';
import { Chart as ChartJS, LinearScale, PointElement, BarElement, Tooltip, Legend, CategoryScale, Title } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { connect } from 'react-redux';
import NavBarComp from './navbarcomp.component';
import UserService from '../services/user.service';

ChartJS.register(CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend);

class Consommation extends Component {
    constructor(props) {
        super(props);
        this.currentUser = props.user;
        this.state = {
            data: null,
            allData: null,
            semaine: null,
            maxSemaine: null,
            semaine_text: null,
            loading: true,
            error: null,
            firstSemaine: null,
            lastSemaine: true
        };
        this.handleLastWeekData = this.handleLastWeekData.bind(this);
        this.handleNextWeekData = this.handleNextWeekData.bind(this);
    }

    componentWillUnmount() {
        document.removeEventListener("visibilitychange", this.handleVisibilityChange);
    }

    componentDidMount() {
        if (this.currentUser.experiment_state !== 3) {
            this.props.history.push("/user");
        }
        UserService.getMinUserWeek(this.currentUser.username)
            .then(response => {
                this.setState({ firstSemaine: response.data })
            })
        UserService.getUserLastConsumption(this.currentUser.username)
            .then(response => {
                this.setState({ semaine: response.data.week, semaine_text: response.data.week_text, maxSemaine: response.data.week });
            })
            .then(() => {
                UserService.getConsumptionData(this.currentUser.username, this.state.semaine)
                    .then(response => {
                        const weekUserData = response.data;
                        const dataXY = [{ x: 'LU', y: weekUserData.kwh_monday }, { x: 'MA', y: weekUserData.kwh_tuesday }, { x: 'ME', y: weekUserData.kwh_wednesday }, { x: 'JE', y: weekUserData.kwh_thursday }, { x: 'VE', y: weekUserData.kwh_friday }, { x: 'SA', y: weekUserData.kwh_saturday }, { x: 'DI', y: weekUserData.kwh_sunday }]
                        const dataUser = {
                            datasets: [{
                                label: 'Consommation journalière en kWh',
                                data: dataXY,
                                borderColor: this.currentUser.avatarColor,
                                backgroundColor: this.currentUser.avatarColor,
                                opacity: 0.5
                            }]
                        };
                        this.setState({ data: dataUser, loading: false });
                    })
                    .catch(error => {
                        this.setState({ error: error.message, loading: false });
                    });
            });
            window.parent.localStorage.setItem("navHistory", JSON.stringify([{ type: 'consumption-graph', username: this.currentUser.username, 'timestamp': Date.now() }]));
            document.addEventListener("visibilitychange", this.handleVisibilityChange);
    }

    handleVisibilityChange = () => {
        if (document.hidden) {
            const navHistory = JSON.parse(window.parent.localStorage.getItem("navHistory")) || [];
            UserService.analytics(this.currentUser.username, navHistory)
                .then(() => {
                    window.parent.localStorage.setItem("navHistory", JSON.stringify([]));
                })
                .catch(error => {
                    this.setState({ error: error.message });
                });
        } else {
            window.parent.localStorage.setItem("navHistory", JSON.stringify([{ type: 'consumption-graph', username: this.currentUser.username, 'timestamp': Date.now() }]));
        }
    }

    handleLastWeekData() {
        UserService.getConsumptionData(this.currentUser.username, this.state.semaine - 1)
            .then(response => {
                const weekUserData = response.data;
                const dataXY = [{ x: 'LU', y: weekUserData.kwh_monday }, { x: 'MA', y: weekUserData.kwh_tuesday }, { x: 'ME', y: weekUserData.kwh_wednesday }, { x: 'JE', y: weekUserData.kwh_thursday }, { x: 'VE', y: weekUserData.kwh_friday }, { x: 'SA', y: weekUserData.kwh_saturday }, { x: 'DI', y: weekUserData.kwh_sunday }];
                const dataUser = {
                    datasets: [{
                        label: 'Consommation journalière en kWh',
                        data: dataXY,
                        borderColor: this.currentUser.avatarColor,
                        backgroundColor: this.currentUser.avatarColor,
                        opacity: 0.5
                    }]
                };
                this.setState({ semaine: this.state.semaine - 1, semaine_text: response.data.week_text, data: dataUser, loading: false });
            })
            .catch(error => {
                return;
            });
    }

    handleNextWeekData() {
        if (this.state.semaine === this.state.maxSemaine) return;
        UserService.getConsumptionData(this.currentUser.username, this.state.semaine + 1)
            .then(response => {
                const weekUserData = response.data;
                const dataXY = [{ x: 'LU', y: weekUserData.kwh_monday }, { x: 'MA', y: weekUserData.kwh_tuesday }, { x: 'ME', y: weekUserData.kwh_wednesday }, { x: 'JE', y: weekUserData.kwh_thursday }, { x: 'VE', y: weekUserData.kwh_friday }, { x: 'SA', y: weekUserData.kwh_saturday }, { x: 'DI', y: weekUserData.kwh_sunday }]
                const dataUser = {
                    datasets: [{
                        label: 'Consommation journalière en kWh',
                        data: dataXY,
                        borderColor: this.currentUser.avatarColor,
                        backgroundColor: this.currentUser.avatarColor,
                        opacity: 0.5
                    }]
                };
                this.setState({ firstSemaine: false, semaine: this.state.semaine + 1, semaine_text: response.data.week_text, data: dataUser, loading: false });
            })
            .catch(error => {
                this.setState({ error: error.message, loading: false });
            });
    }

    render() {
        const { data, loading, error } = this.state;

        if (loading) {
            return <div>Loading...</div>;
        }

        if (error) {
            return <div>Error: {error}</div>;
        }

        const options = {
            responsive: true,
            maintainAspectRatio: false,

            scales: {
                y: {
                    type: "linear",
                    ticks: {
                        stepSize: 1,
                        font: {
                            size: 18
                        }
                    }
                },
            },
            plugins: {
                legend: {
                    display: false,

                },
                title: {
                    display: false,
                },
            },
        };


        return (
            <div className='graph-container'>
                <NavBarComp page={"chat"} username={this.currentUser.username} />
                <div className="graph-title"><b id="eco1">consommation</b> → journalière en kWh</div>
                <div className='graph-conso'>
                    <div className='semaine-div'>
                        {this.state.firstSemaine === this.state.semaine && (
                            <span className='arrow-gray'>&larr;</span>
                        )}
                        {this.state.firstSemaine < this.state.semaine && (
                            <span onClick={this.handleLastWeekData} className='arrow'>&larr;</span>
                        )
                        }
                        {this.state.semaine_text}
                        {this.state.semaine === this.state.maxSemaine && (
                            <span className='arrow-gray'>&rarr;</span>
                        )}
                        {this.state.semaine < this.state.maxSemaine && (
                            <span onClick={this.handleNextWeekData} className='arrow'>&rarr;</span>
                        )}
                    </div>
                    <Bar options={options} data={data} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.auth.user
});

export default connect(mapStateToProps)(Consommation);
