import React, { Component } from "react";
import { connect } from "react-redux";
import { Dots } from "./dots.component";
import ChatMessage from "./chat-message.component";
import UserService from "../services/user.service";
import NavBarComp from "./navbarcomp.component";


class Chat extends Component {
    constructor(props) {
        super(props);
        this.currentUser = props.user;
        this.state = {
            batteryState: 0,
            userInput: "",
            prompt_index: 0,
            chatMessages: [],
            isChatting: false,
            imgSrc: 1,
            isWaiting: false,
            isWaitingForChat: false,
            isWaitingForQuestion: false,
            navHistory: []
        };
    }

    componentDidUpdate() {
        window.parent.localStorage.setItem("userState", JSON.stringify(this.state));
        this.scrollToBottom();
    }

    /**
     * Supprime l'écouteur d'événement lors du démontage du composant.
     */
    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleEnterPress);
        document.removeEventListener("visibilitychange", this.handleVisibilityChange);
    }

    componentDidMount() {
        if (this.currentUser.experiment_state > 3) {
            this.props.history.push("/user");
        }
        const savedState = JSON.parse(window.parent.localStorage.getItem("userState"));
        if (savedState && savedState.chatMessages && savedState.chatMessages.length > 0) {
            this.setState(savedState);
        }
        else {
            this.setState({ isChatting: true });
            UserService.getChatMessages()
                .then((responseChat) => {
                    const startChatMessages = [];
                    const chatMessagesFromDatabase = [...startChatMessages, ...responseChat.data]
                    this.setState({ chatMessages: chatMessagesFromDatabase });
                })
                .catch((error) => {

                });
        }
        window.parent.localStorage.setItem("navHistory", JSON.stringify([{ type: 'chat', username: this.currentUser.username, 'timestamp': Date.now() }]));
        document.addEventListener("keydown", this.handleEnterPress);
        document.addEventListener("visibilitychange", this.handleVisibilityChange);
    }
            

    handleVisibilityChange = () => {
        if (document.hidden) {
            const navHistory = JSON.parse(window.parent.localStorage.getItem("navHistory")) || [];
            UserService.analytics(this.currentUser.username, navHistory)
                .then(() => {
                    window.parent.localStorage.setItem("navHistory", JSON.stringify([]));
                })
                .catch(error => {
                    this.setState({ error: error.message });
                });
        }else{
            window.parent.localStorage.setItem("navHistory", JSON.stringify([{ type: 'chat', username: this.currentUser.username, 'timestamp': Date.now() }]));
        }
    }

    handleEnterPress = (event) => {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault();
            this.handleUserQuestion();
        }
    }

    handleUserQuestion = () => {
        const userQuestion = document.getElementById("user-question").value;
        if (userQuestion === "" || this.state.isWaitingForChat) {
            return;
        }
        this.setState({ isWaitingForChat: true });
        document.getElementById("user-question").value = "";
        const dbResponse = {
            user_id: this.currentUser.username,
            user_group: this.currentUser.group,
            prompt_type: "user",
            prompt_text: userQuestion,
            timestamp: Date.now()
        }
        const { chatMessages } = this.state;
        const userResponse = { role: "user", content: userQuestion, id: chatMessages.length + 1 };
        let updatedChatMessages = [...chatMessages, userResponse];
        this.setState({ chatMessages: updatedChatMessages });
        UserService.submitChatMessages(dbResponse).then((response) => {
            const electricianResponse = response.data;
            electricianResponse.id = updatedChatMessages.length + 1;
            updatedChatMessages = [...updatedChatMessages, electricianResponse];
            this.setState({ chatMessages: updatedChatMessages }, () => {
                this.setState({ isWaitingForChat: false });
            });
        }).then(() => {
            this.forceUpdate();
        }).catch((error) => {
            const chatError = { role: "assistant", content: "Je m'excuse, je suis momentanément débordé. N'hésitez pas à quitter et à revenir dans le chat dans quelques minutes, je serai là pour vous aider 😊", id: updatedChatMessages.length + 1 };
            updatedChatMessages = [...updatedChatMessages, chatError];
            this.setState({ chatMessages: updatedChatMessages }, () => {
                this.setState({ isWaitingForChat: false });
                this.forceUpdate();
            });
        })
        document.getElementById("user-question").value = "";
    };
    scrollToBottom() {
        const chatContainer = document.querySelector('#chat-container');
        chatContainer.scrollTop = chatContainer.scrollHeight;
    }

    render() {
        const contentFirstMessage = "Bonjour et bienvenue sur INFINEED ! Le défi a commencé et dure 6 semaines. Je suis INFI, votre assistant personnel, ici pour vous aider à économiser de l’électricité. Voici un conseil pour démarrer : séchez vos vêtements à l'air libre. Cela peut vous faire économiser jusqu'à 8 % de votre électricité. Bonne chance et n'hésitez pas à me demander d'autres conseils ! 🌱"
        let smiley = 'green';
        if (this.currentUser.avatarColor === '#f6fe8f') {
            smiley = "jaune";
        } else if (this.currentUser.avatarColor === '#ffcf60') {
            smiley = "orange";
        } else if (this.currentUser.avatarColor === '#fead8f') {
            smiley = "saumon";
        } else if (this.currentUser.avatarColor === '#a8c0ff') {
            smiley = "violet";
        } else if (this.currentUser.avatarColor === '#5af9ff') {
            smiley = "blue";
        }
        return (
            <div>
                <NavBarComp page="chat" username={this.currentUser.username} />
                <div className="chat-container align-bottom" id="chat-container">
                    <div className="first-chat">
                        <div><img src={`smiley/smiley-${smiley}.svg`} id="question-logo" alt="" /></div>
                        <ChatMessage
                            content={contentFirstMessage}
                            isQuestion={true}
                            isChatBot={true}
                            avatarColor={this.currentUser.avatarColor}
                        />
                    </div>
                    <div className="chatbot">
                        {this.state.chatMessages.map((message, index) => {
                            if (message.role !== "system") {
                                return (<div key={index} id={index} className={message.role === "user" ? "user_interaction" : "chat_interaction"}>
                                    {message.role !== "user" && <div><img src={`smiley/smiley-${smiley}.svg`} id="question-logo" alt="" /></div>}
                                    <ChatMessage
                                        content={message.content}
                                        isQuestion={message.role !== "user"}
                                        isChatBot={true}
                                        avatarColor={this.currentUser.avatarColor}
                                    />
                                </div>)
                            }
                            return <div key={index}></div>;
                        })}
                        {this.state.isWaitingForChat && (<div id="waiting"><ChatMessage
                            content={<Dots />}
                            isQuestion={true}
                            isChatBot={true}
                            avatarColor={this.currentUser.avatarColor}
                        /></div>)}
                    </div>
                    <div id="chat">
                        <div id="linebreak-container">
                            <div id="linebreak"></div>
                        </div>
                        <div className="user-question-input">
                            <input type="text"
                                id="user-question"
                                placeholder="Message" />
                            <button id="sendMessage" onClick={this.handleUserQuestion}>ENVOYER</button>
                        </div>
                    </div>
                </div></div>
        );
    }

}

function mapStateToProps(state) {
    const { user } = state.auth;
    return {
        user,
    };
}


export default connect(mapStateToProps)(Chat);
