import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import "./index.css";
import App from "./App";
import queryString from 'query-string';
import { registerLicense } from '@syncfusion/ej2-base';
// Registering Syncfusion license key (Linear Gauge)
registerLicense('Ngo9BigBOggjHTQxAR8/V1NGaF1cXGFCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWX5cc3RXQmVeUER+Wks=');
const parsedParams = queryString.parse(window.location.search);
const userId = parsedParams.userId;
const token = parsedParams.token;
const userGroup = parsedParams.userGroup;

ReactDOM.render(
  <Provider store={store}>
    <App userId={userId} token={token} userGroup={userGroup}/>
  </Provider>,
  document.getElementById("root")
);

//serviceWorker.unregister();
