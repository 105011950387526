import { Component } from "react";

class ExperimentState extends Component {

    render() {
        return (
            <div>
                {
                this.props.expState === 1 && (
                    <div id="experiment_state">
                        <img src="interface/sleep.svg" className="state_image" alt="sleep" />
                        <p className="state_text">Le défi commence le 19 Août</p>
                    </div>
                )
                }
                {
                this.props.expState === 2 && (
                    <div id="experiment_state">
                        <img src="interface/wokenup.svg" className="state_image" alt="wokenup" />
                        <p className="state_text">Premiers résultats : Début de la semaine prochaine</p>
                    </div>
                )
                }
                {
                this.props.expState === 4 && (
                    <div id="experiment_state">
                        <img src="interface/sleep.svg" className="state_image" alt="sleep" />
                        <p className="state_text">Le défi est terminée, merci de votre participation !</p>
                    </div>
                )
                }
            </div>
        );
    }
}

export default ExperimentState;