import axios from "axios";
const API_URL = process.env.REACT_APP_SERVER + "/api/auth/";

class AuthService {

  signup(user_id, token, checked) {
    return axios.post(API_URL + "signup-qualtrics", { username: user_id, token: token, checked: checked }).then((response) => {
      let data = response.data;
      data.prolific_id = user_id;
      data.imgSrc = 1;
      Object.defineProperty(data, "avatarColor", Object.getOwnPropertyDescriptor(data, "color"));
      delete data["color"];
      if (data.accessToken) {
        window.parent.localStorage.setItem("user", JSON.stringify(data));
      }
      return data;
    });
  }
}

const authService = new AuthService()
export default authService;