import React, { Component } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import UserService from '../services/user.service';
import MessageNotif from './message-notif.component';

class NavBarComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notif: false,
            page: props.page,
            color: props.color,
            username: props.username
        };
    }

    componentDidMount() {
        UserService.getMessageState(this.state.username)
            .then((response) => {
                this.setState({ notif: response.data });
            }).catch((error) => {
                console.log(error);
            });
    }
    


    render() {
        return (
            <Navbar className="bg-body-tertiary navbar">
                <Container>
                    {(this.state.page === "chat" || this.state.page === "parameters") && (
                        <Navbar.Brand href="/user"><img src='interface/arrow.svg' /></Navbar.Brand>
                    )}
                    {this.state.page !== "chat" && this.state.page !== "parameters" && this.state.notif === true && (
                        <Navbar.Brand href="/chat"><MessageNotif notif={true} color={this.state.color} /></Navbar.Brand>
                    )}
                    {this.state.page !== "chat" && this.state.page !== "parameters" && this.state.notif === false && (
                        <Navbar.Brand href="/chat"><MessageNotif notif={false} color={this.state.color} /></Navbar.Brand>
                    )}
                    <Navbar.Toggle />
                    {this.state.page === "user" && (<Navbar.Collapse className="justify-content-end">
                        <Navbar.Brand href="/parameters"><img src='interface/settings.svg' /></Navbar.Brand>
                    </Navbar.Collapse>)}
                    {this.state.page === "parameters" && (<Navbar.Collapse className="justify-content-end">
                        <Navbar.Brand href="/deconnexion"><div id="logout_button">Déconnexion</div></Navbar.Brand>
                    </Navbar.Collapse>)}
                </Container>
            </Navbar>
        );
    }
};

export default NavBarComp;