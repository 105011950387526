import React, { Component } from 'react';
import { connect } from 'react-redux';
import userService from '../services/user.service';

class CouleurSelector extends Component {
    constructor(props) {
        super(props);
        this.currentUser = props.user;
        this.state = {
            avatarColor: this.currentUser.avatarColor
        }
    }

    componentDidUpdate(){
        window.parent.localStorage.setItem("user", JSON.stringify(this.currentUser));
    }

    handleChangeAvatarColor = (color) => {
        document.querySelector(':root').style.setProperty('--avatar-color', color);
        this.setState({avatarColor: color});
        this.currentUser.avatarColor = color;
        userService.changeUserColor(this.currentUser.username, color);
        this.forceUpdate();
    }

    render() {
        return (
            <div id='color-grid'>
                <div className={this.state.avatarColor === '#f6fe8f' ? 'selected' : ''} onClick={()=>this.handleChangeAvatarColor("#f6fe8f")}>
                    <img src='colors/jaune-up.svg' id='jaune' />
                </div>
                <div className={this.state.avatarColor === '#ffcf60' ? 'selected' : ''} onClick={()=>this.handleChangeAvatarColor("#ffcf60")}>
                    <img src='colors/orange-up.svg' id='orange' />
                </div>
                <div className={this.state.avatarColor === '#fead8f' ? 'selected' : ''} onClick={()=>this.handleChangeAvatarColor("#fead8f")}>
                    <img src='colors/saumon-up.svg' id='saumon' />
                </div>
                <div className={this.state.avatarColor === '#a8c0ff' ? 'selected' : ''} onClick={()=>this.handleChangeAvatarColor("#a8c0ff")}>
                    <img src='colors/violet-up.svg' id='violet' />
                </div>
                <div className={this.state.avatarColor === '#5af9ff' ? 'selected' : ''} onClick={()=>this.handleChangeAvatarColor("#5af9ff")}>
                    <img src='colors/bleu-up.svg' id='bleu' />
                </div>
                <div className={this.state.avatarColor === '#92ffa9' ? 'selected' : ''} onClick={()=>this.handleChangeAvatarColor("#92ffa9")}>
                    <img src='colors/vert-up.svg' id='vert' />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user } = state.auth;
    return {
        user,
    };
}

export default connect(mapStateToProps)(CouleurSelector);
