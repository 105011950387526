import React, { Component } from "react";
import { connect } from "react-redux";
import { Router, Switch, Route } from "react-router-dom";
import SignupFromQualtrics from "./components/signup-qualtrics.component";
import PrivateRoute from "./components/private-route.component";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import User from "./components/user.component";
import Chat from "./components/chat.component";
import { clearMessage } from "./actions/message";
import { history } from './helpers/history';
import Parameters from "./components/parameters.component";
import Classement from "./components/classement.component";
import Deconnexion from "./components/deconnexion.component";
import Consommation from "./components/consommation.component";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: undefined,
    };

    this.prolific_id = undefined;

    history.listen((location) => {
      props.dispatch(clearMessage());
    });
  }

  componentDidMount() {
    const user = this.props.user;

    if (user) {
      this.setState({
        currentUser: user
      });
    }
  }


  render() {

    //Rendu de la page avec une route de signup et la page User qui est une page nécessitant authentification (PrivateRoute)
    return (
      <Router history={history}>
            <Switch>
              <Route exact path="/" component={SignupFromQualtrics}/>
              <PrivateRoute exact path="/user" component={User} />
              <PrivateRoute exact path="/chat" component={Chat} />
              <PrivateRoute exact path="/parameters" component={Parameters} />
              <PrivateRoute exact path="/classement" component={Classement} />
              <PrivateRoute exact path="/consommation" component={Consommation} />
              <PrivateRoute exact path="/deconnexion" component={Deconnexion} />
            </Switch>
      </Router>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(App);