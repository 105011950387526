import React, { Component } from 'react';
import { Chart as ChartJS, LinearScale, PointElement, LineElement, Tooltip, Legend } from 'chart.js';
import { Scatter } from 'react-chartjs-2';
import { connect } from 'react-redux';
import NavBarComp from './navbarcomp.component';
import annotationPlugin from 'chartjs-plugin-annotation';
import CustomLinearGauge from './custom-lineargauge.component';
import chroma from 'chroma-js';
import UserService from '../services/user.service';

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend, annotationPlugin);

class Classement extends Component {
    constructor(props) {
        super(props);
        this.currentUser = props.user;
        this.state = {
            data: null,
            allData: null,
            loading: true,
            error: null,
        };
    }

    getColor(percentage) {
        return chroma.scale(['#D8D8D8', this.currentUser.avatarColor]).domain([-1, 11])(percentage).hex();
    }

    mapDataToPoints(data) {
        let listPoints = [];
        for (let i = 0; i < data.length; i++) {
            listPoints.push({
                x: Math.random(),
                y: parseFloat(data[i]),
                color: this.getColor(parseFloat(data[i]))
            });
        }
        const pointsBackgroundColor = listPoints.map(user => user.color);
        return {
            label: '',
            backgroundColor: 'transparent',
            data: listPoints,
            pointBackgroundColor: this.currentUser.userGroup !== "3" && this.currentUser.userGroup !== "1" ? pointsBackgroundColor : "transparent",
            borderColor: 'transparent',
            pointRadius: 5,
        };
    }

    componentWillUnmount() {
        document.removeEventListener("visibilitychange", this.handleVisibilityChange);
    }

    componentDidMount() {
        if (this.currentUser.experiment_state !== 3) {
            this.props.history.push("/user");
        }
        UserService.getAllUsersConsumptionWithSameTreat(this.currentUser.username)
            .then(response => {
                const dataUser = {
                    datasets: [this.mapDataToPoints(response.data)]
                };
                this.setState({ data: dataUser, loading: false });
            })
            .catch(error => {
                this.setState({ error: error.message, loading: false });
                window.parent.localStorage.removeItem("user");
                window.parent.localStorage.removeItem("userState");
                window.location.href = "/";
            });
        window.parent.localStorage.setItem("navHistory", JSON.stringify([{ type: 'comparison-graph', username: this.currentUser.username, 'timestamp': Date.now() }]));
        document.addEventListener("visibilitychange", this.handleVisibilityChange);

    }

    handleVisibilityChange = (e) => {
        e.preventDefault();
        if (document.hidden) {
            const navHistory = JSON.parse(window.parent.localStorage.getItem("navHistory")) || [];
            UserService.analytics(this.currentUser.username, navHistory)
                .then(() => {
                    window.parent.localStorage.setItem("navHistory", JSON.stringify([]));
                })
                .catch(error => {
                    this.setState({ error: error.message });
                });
        } else {
            window.parent.localStorage.setItem("navHistory", JSON.stringify([{ type: 'comparison-graph', username: this.currentUser.username, 'timestamp': Date.now() }]));
        }
    }

    render() {
        const { data, loading, error } = this.state;

        if (loading) {
            return <div>Loading...</div>;
        }

        if (error) {
            return <div>Error: {error}</div>;
        }

        let smiley = 'green';
        if (this.currentUser.avatarColor === '#f6fe8f') {
            smiley = "jaune";
        } else if (this.currentUser.avatarColor === '#ffcf60') {
            smiley = "orange";
        } else if (this.currentUser.avatarColor === '#fead8f') {
            smiley = "saumon";
        } else if (this.currentUser.avatarColor === '#a8c0ff') {
            smiley = "violet";
        } else if (this.currentUser.avatarColor === '#5af9ff') {
            smiley = "blue";
        }

        const image = new Image();
        image.src = `smiley/smiley-${smiley}.svg`;
        image.type = 'image/svg+xml';
        image.alt = 'avatar';

        let avatarPlacemant = -0.7;
        if (this.currentUser.batteryState >= 10.7) {
            avatarPlacemant = 10.7;
        } else if (this.currentUser.batteryState > -0.7) {
            avatarPlacemant = this.currentUser.batteryState;
        }

        let annot = {
            line10: {
                type: 'line',
                yMin: 10,
                yMax: 10,
                borderColor: 'grey',
                borderWidth: 1,
                borderDash: [25, 25]
            },
            line9: {
                type: 'line',
                yMin: 9,
                yMax: 9,
                borderColor: 'grey',
                borderWidth: 1,
                borderDash: [25, 25]
            },
            line8: {
                type: 'line',
                yMin: 8,
                yMax: 8,
                borderColor: 'grey',
                borderWidth: 1,
                label: {
                    display: true,
                    content: 'EXCELLENTE',
                    color: 'grey',
                    backgroundColor: 'transparent',
                    position: 'start',
                    drawTime: 'afterDraw',
                    z: 0,
                    padding: {
                        left: 0,
                        bottom: 4
                    },
                    yAdjust: -10
                }
            },
            line7: {
                type: 'line',
                yMin: 7,
                yMax: 7,
                borderColor: 'grey',
                borderWidth: 1,
                borderDash: [25, 25]
            },
            line6: {
                type: 'line',
                yMin: 6,
                yMax: 6,
                borderColor: 'grey',
                borderWidth: 1,
                label: {
                    display: true,
                    content: 'REMARQUABLE',
                    color: 'grey',
                    backgroundColor: 'transparent',
                    position: 'start',
                    drawTime: 'afterDraw',
                    z: 0,
                    padding: {
                        left: 0,
                        bottom: 4
                    },
                    yAdjust: -10
                }
            },
            line5: {
                type: 'line',
                yMin: 5,
                yMax: 5,
                borderColor: 'grey',
                borderWidth: 1,
                borderDash: [25, 25]
            },
            line4: {
                type: 'line',
                yMin: 4,
                yMax: 4,
                borderColor: 'grey',
                borderWidth: 1,
                label: {
                    display: true,
                    content: 'TRES BONNE',
                    color: 'grey',
                    backgroundColor: 'transparent',
                    position: 'start',
                    drawTime: 'afterDraw',
                    z: 0,
                    padding: {
                        left: 0,
                        bottom: 4
                    },
                    yAdjust: -10
                }
            },
            line3: {
                type: 'line',
                yMin: 3,
                yMax: 3,
                borderColor: 'grey',
                borderWidth: 1,
                borderDash: [25, 25]
            },
            line2: {
                type: 'line',
                yMin: 2,
                yMax: 2,
                borderColor: 'grey',
                borderWidth: 1,
                label: {
                    display: true,
                    content: 'BONNE',
                    drawTime: 'afterDraw',
                    z: 0,
                    yAdjust: -10,
                    backgroundColor: 'transparent',
                    position: 'start',
                    padding: {
                        left: 0,
                        bottom: 4
                    },
                    color: 'grey'
                }
            },
            line1: {
                type: 'line',
                yMin: 1,
                yMax: 1,
                borderColor: 'grey',
                borderWidth: 1,
                borderDash: [25, 25]
            },
            line0: {
                type: 'line',
                yMin: 0,
                yMax: 0,
                borderColor: 'grey',
                borderWidth: 1,
                label: {
                    display: true,
                    content: 'MODESTE',
                    drawTime: 'afterDraw',
                    z: 0,
                    yAdjust: -10,
                    backgroundColor: 'transparent',
                    position: 'start',
                    padding: {
                        left: 0,
                        bottom: 4
                    },
                    color: 'grey'
                }
            },
            point: {
                type: 'label',
                drawTime: 'afterDraw',
                content: image,
                width: 20,
                height: 20,
                xValue: 0.5,
                yValue: avatarPlacemant
            }
        }

        if (this.currentUser.userGroup === "3" || this.currentUser.userGroup === "4") {
            annot = {
                ...annot, line0_bonus: {
                    type: 'line',
                    yMin: 0,
                    yMax: 0,
                    borderColor: 'grey',
                    borderWidth: 1,
                    label: {
                        display: true,
                        content: '5 CHF',
                        drawTime: 'afterDraw',
                        z: 0,
                        yAdjust: -10,
                        backgroundColor: 'transparent',
                        position: 'end',
                        padding: {
                            left: 0,
                            bottom: 4
                        },
                        color: 'grey'
                    }
                },
                line2_bonus: {
                    type: 'line',
                    yMin: 2,
                    yMax: 2,
                    borderColor: 'grey',
                    borderWidth: 1,
                    label: {
                        display: true,
                        content: '10 CHF',
                        drawTime: 'afterDraw',
                        z: 0,
                        yAdjust: -10,
                        backgroundColor: 'transparent',
                        position: 'end',
                        padding: {
                            left: 0,
                            bottom: 4
                        },
                        color: 'grey'
                    }
                },
                line4_bonus: {
                    type: 'line',
                    yMin: 4,
                    yMax: 4,
                    borderColor: 'grey',
                    borderWidth: 1,
                    label: {
                        display: true,
                        content: '20 CHF',
                        drawTime: 'afterDraw',
                        z: 0,
                        yAdjust: -10,
                        backgroundColor: 'transparent',
                        position: 'end',
                        padding: {
                            left: 0,
                            bottom: 4
                        },
                        color: 'grey'
                    }
                },
                line6_bonus: {
                    type: 'line',
                    yMin: 6,
                    yMax: 6,
                    borderColor: 'grey',
                    borderWidth: 1,
                    label: {
                        display: true,
                        content: '30 CHF',
                        drawTime: 'afterDraw',
                        z: 0,
                        yAdjust: -10,
                        backgroundColor: 'transparent',
                        position: 'end',
                        padding: {
                            left: 0,
                            bottom: 4
                        },
                        color: 'grey'
                    }
                },
                line8_bonus: {
                    type: 'line',
                    yMin: 8,
                    yMax: 8,
                    borderColor: 'grey',
                    borderWidth: 1,
                    label: {
                        display: true,
                        content: '50 CHF',
                        drawTime: 'afterDraw',
                        z: 0,
                        yAdjust: -10,
                        backgroundColor: 'transparent',
                        position: 'end',
                        padding: {
                            left: 0,
                            bottom: 4
                        },
                        color: 'grey'
                    }
                }
            }
        }

        return (
            <div className='graph-container'>
                <NavBarComp page={"chat"} username={this.currentUser.username} />
                {(this.currentUser.userGroup === "2" || this.currentUser.userGroup === "4") && (
                    <div className="graph-title"><b id="eco1">votre économie</b> [ <img src={`./smiley/smiley-${smiley}.svg`} className='image-text' type='image/svg+xml'></img> ] → en % depuis le début du défi par rapport aux ménages comparables [
                        <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="10" cy="10" r="8" fill={this.currentUser.avatarColor} />
                        </svg>
                        ]</div>
                )}{(this.currentUser.userGroup === "1" || this.currentUser.userGroup === "3") && (
                    <div className="graph-title"><b id="eco1">votre économie</b>  [ <img src={`./smiley/smiley-${smiley}.svg`} className='image-text' type='image/svg+xml'></img> ] → en % depuis le début du défi</div>
                )}
                <div className='graph'>
                    <CustomLinearGauge batteryState={this.currentUser.batteryState} avatarColor={this.currentUser.avatarColor} page={'classement'} />
                    <Scatter
                        data={data}
                        datasetIdKey={null}
                        options={{
                            maintainAspectRatio: false,
                            scales: {
                                y: {
                                    min: -1,
                                    max: 11,
                                    type: "linear",
                                    ticks: {
                                        stepSize: 1,
                                        callback: value => `${value !== -1 && value !== 11 ? `${value}%` : ''}`
                                    },
                                    border: {
                                        display: false
                                    },
                                    grid: {
                                        display: false
                                    }
                                },
                                x: {
                                    display: false,
                                    min: 0,
                                    max: 1,
                                    type: "linear",
                                }
                            },
                            plugins: {
                                legend: {
                                    display: false,

                                },
                                title: {
                                    display: false,
                                },
                                tooltip: {
                                    enabled: false
                                },
                                annotation: {
                                    annotations: annot
                                }
                            }
                        }}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.auth.user
});

export default connect(mapStateToProps)(Classement);
