import React from 'react';
class MessageNotif extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          notif: props.notif
        };
      }
      
    render() {
        return (
            <div>
                {
                    this.state.notif === true && (<img src='./interface/message-notif.svg'></img>
                    )}
                    {this.state.notif === false && (
                        <img src='./interface/message.svg'></img>                  
                    )}
            </div>
        );
    }
}

export default MessageNotif;