import React, { Component } from "react";
import { connect } from "react-redux";
import { signup } from "../actions/auth";


/**
 * Composant qui gère l'authentification d'un utilisateur à partir de Qualtrics.
 */
class SignupFromQualtrics extends Component {

  constructor(props) {
    super(props);
    this.state = {
      error: undefined,
    };
  }

  componentDidMount() {
    if (this.props.user) {
      this.props.history.push("/user");
      window.location.reload();
    }
  }

  handleSignup() {
    const { dispatch, history } = this.props;
    const userId = document.getElementById("userId").value;
    const token = document.getElementById("token").value;
    const policy = document.getElementById("policy").checked;
    dispatch(signup(userId, token, policy))
      .then(() => {
        history.push("/user");
        window.location.reload();
      })
      .catch((error) => {
        document.getElementById("userId").value = '';
        document.getElementById("token").value = '';
        document.getElementById("policy").checked = false;
        this.setState({ error: "Une erreur est survenue, veuillez vérifier votre nom d'utilisateur et votre mot de passe et accepter la politique de confidentialité." });
      });
  }


  render() {
    const { error } = this.state;
    return (
      <div id="login-page">
        <form>
          <div className="form-group">
            <img src="logo.svg" alt="logo" />
            <div className="login">
              <div id='login-username'>
                LOGIN
                <label className="label-login" htmlFor="userId">Numéro de téléphone</label>
              </div>
              <img src="6.svg" id="avatarLogin" alt="avatar" />
            </div>
            <input
              type="text"
              className="form-control"
              id="userId"
              placeholder="0780176690"
              required
            />
            <label className="label-login" htmlFor="token">Mot de passe</label>
            <input
              type="password"
              className="form-control"
              id="token"
              placeholder="Mot de passe"
              required
            />
            <div className="div-policy">
              <input
              type="checkbox"
              className="form-check-input"
              id="policy"
            />
              <label>J'accepte la <a href="https://infineed.app/privacy-policy" target="_blank">Politique de Confidentialité</a></label>
            </div>
            <button
              type="button"
              className="bouton-valider"
              onClick={() => this.handleSignup()}
            >VALIDER</button>
          </div>
        </form>
        {error && <div className="alert alert-danger" role="alert">{error}</div>}
      </div>);
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}


export default connect(mapStateToProps)(SignupFromQualtrics);
