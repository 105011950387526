import { Component } from "react";
import { LinearGaugeComponent, AxesDirective, AxisDirective, PointersDirective, PointerDirective, RangesDirective, RangeDirective } from '@syncfusion/ej2-react-lineargauge';
import chroma from "chroma-js";
export class CustomLinearGauge extends Component {
    multipleRange;
    constructor(props) {
        super(props);
        this.state = {
            batteryState: props.batteryState,
            avatarColor: props.avatarColor,
            page : props.page,
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight
        }
    }
    load(args) {
        this.verticalGauge.bind(this);
    }

    verticalGauge(e) {
        this.multipleRange.orientation = "Vertical";
        if (e.currentTarget != null) {
            e.currentTarget['style']['color'] = "white";
            e.currentTarget['style']['backgroundColor'] = "#0074E3";
            document.getElementById('horizontal')['style']['color'] = "black";
        }
    }

    colorGrad() {
        const startColor = '#D8D8D8'
        const scale = chroma.scale([startColor, this.state.avatarColor]);
        const numSteps = 7;
        const gradientColors = [];
        for (let i = 0; i < numSteps; i++) {
            const color = scale(i / (numSteps - 1)).hex();
            gradientColors.push(color);
        }
        return gradientColors;
    }


    render() {
        const { batteryState, avatarColor } = this.props;
        const colorGrads = this.colorGrad();
        return (
            <div className={`control-pane-${this.state.windowWidth>=this.state.windowHeight ? 'large':'small'} ${this.state.page}-pane`}>
                <LinearGaugeComponent load={this.load.bind(this)} allowMargin={false} id='multipleRange' height={this.state.page === 'user'?'340px':'104%'} width={this.state.page === 'user'?'100%':'30px'} orientation='Vertical' background='transparent' ref={multipleRange => this.multipleRange = multipleRange}>
                    <AxesDirective>
                        <AxisDirective minimum={-1} maximum={11}>
                            <PointersDirective>
                                <PointerDirective width={20} opposedPosition={true} value={batteryState} placement="Near" animationDuration={2000} color="black" position="Cross" markerType="InvertedTriangle" />
                            </PointersDirective>
                            <RangesDirective>
                                <RangeDirective start={-1} end={0} color={colorGrads[0]} startWidth={50} endWidth={50} offset={5}></RangeDirective>
                                <RangeDirective start={0} end={2} color={colorGrads[1]} startWidth={50} endWidth={50} offset={5}></RangeDirective>
                                <RangeDirective start={2} end={4} color={colorGrads[2]} startWidth={50} endWidth={50} offset={5}></RangeDirective>
                                <RangeDirective start={4} end={6} color={colorGrads[3]} startWidth={50} endWidth={50} offset={5}></RangeDirective>
                                <RangeDirective start={6} end={8} color={colorGrads[4]} startWidth={50} endWidth={50} offset={5}></RangeDirective>
                                <RangeDirective start={8} end={10} color={colorGrads[5]} startWidth={50} endWidth={50} offset={5}></RangeDirective>
                                <RangeDirective start={10} end={11} color={avatarColor} startWidth={50} endWidth={50} offset={5}></RangeDirective>
                            </RangesDirective>
                        </AxisDirective>
                    </AxesDirective>
                </LinearGaugeComponent>
            </div>
        );
    };
}

export default CustomLinearGauge;
