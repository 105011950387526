import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

/**
 * Composant qui gère les routes privées.
 * @param {Component} component - Le composant à sécuriser.
 * @param {boolean} isAuthenticated - Indique si l'utilisateur est authentifié.
 * @param {object} rest - Les autres paramètres de la route. 
 * @returns {JSX.Element} - Le composant sécurisé si l'utilisateur est authentifié.
 */
const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  const renderComponent = (props) =>
    isAuthenticated ? <Component {...props} /> : <Redirect to="/" />;

  return <Route {...rest} render={renderComponent} />;
};

function mapStateToProps(state){
  return {
    isAuthenticated: state.auth.user !== null,
  };
}

export default connect(mapStateToProps)(PrivateRoute);
