import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_SERVER + "/user/";

class UserService {
  
  submitChatMessages(chatMessages){
    return axios.post(API_URL + "chat-messages", chatMessages,{headers: authHeader()});
  }

  getUserLastConsumption(user_id){
    return axios.get(API_URL + `user-last-consumption/${user_id}`, { headers: authHeader() });
  }

  getAllUsersConsumptionWithSameTreat(user_id){
    return axios.get(API_URL + `all-users-consumption-same-segment/${user_id}`, { headers: authHeader() });
  } 

  getConsumptionData(user_id, week){
    return axios.get(API_URL + `user-consumptions/${user_id}/${week}`, { headers: authHeader() });
  }

  getChatMessages(){
    const user_id = JSON.parse(localStorage.getItem('user')).username;
    return axios.get(API_URL + `chat-conv/${user_id}`, { headers: authHeader() });
  }

  getEnergySavingPrompt(question_id, answer_id){
    return axios.get(API_URL + `energy-saving-prompts/${question_id}/${answer_id}`, { headers: authHeader() });
  }

  changeUserColor(user_id, color){
    return axios.post(API_URL + `change-color/${user_id}`, {color}, { headers: authHeader() });
  }

  getMessageState(user_id){
    return axios.get(API_URL + `message-state/${user_id}`, { headers: authHeader() });
  }

  getMinUserWeek(user_id){
    return axios.get(API_URL + `min-user-week/${user_id}`, { headers: authHeader() });
  }

  deconnexion(user_id){
    return axios.get(API_URL + `deconnexion/${user_id}`,{headers: authHeader()});
  }

  getExperimentState(user_id, navHistory){
    return axios.post(API_URL + `get-exp-state/${user_id}`,navHistory, { headers: authHeader() });
  }

  analytics(user_id, navHistory){
    return axios.post(API_URL + `analytics/${user_id}`,navHistory, { headers: authHeader() });
  }
}

export default new UserService();
