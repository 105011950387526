import { React, Component } from 'react';
import NavBarComp from './navbarcomp.component';
import { connect } from "react-redux";
import CouleurSelector from './couleur-selector.component';
import UserService from '../services/user.service';

class Parameters extends Component {
    constructor(props) {
        super(props);
        this.currentUser = props.user;
        this.state = {
            avatarColor: this.currentUser.avatarColor
        }
    }

    componentWillUnmount() {
        document.removeEventListener("visibilitychange", this.handleVisibilityChange);
    }

    componentDidMount() {
        if(this.currentUser.experiment_state>3){
            this.props.history.push("/user");
        }
        window.parent.localStorage.setItem("navHistory", JSON.stringify([{ type: 'parameters', username: this.currentUser.username, 'timestamp': Date.now() }]));
        document.addEventListener("visibilitychange", this.handleVisibilityChange);
    }

    handleVisibilityChange = () => {
        if (document.hidden) {
            const navHistory = JSON.parse(window.parent.localStorage.getItem("navHistory")) || [];
            UserService.analytics(this.currentUser.username, navHistory)
                .then(() => {
                    window.parent.localStorage.setItem("navHistory", JSON.stringify([]));
                })
                .catch(error => {
                    this.setState({ error: error.message });
                });
        } else {
            window.parent.localStorage.setItem("navHistory", JSON.stringify([{ type: 'parameters', username: this.currentUser.username, 'timestamp': Date.now() }]));
        }
    }
    

    render() {
        return (
            <div className="row">
                <div className="col-12" id="energy-save-container">
                    <NavBarComp page="parameters"  username={this.currentUser.username}/>
                    <div id="reglages">
                        <h4 id="text-reglages">réglages</h4>
                        <div id='mon_ident'>MON IDENTIFIANT</div>
                        <div id='identifiant'>{this.currentUser.username}</div>
                        <div id='color_selector'>MA COULEUR D'AVATAR</div>
                        <CouleurSelector/>
                        <div id='plus-info'>PLUS D'INFORMATIONS</div>
                        <a href="https://www.infineed.app/" id='lien-explication' target='blank_'>www.infineed.app</a>
                    </div>
                </div>
            </div>
        );
    }

}
function mapStateToProps(state) {
    const { user } = state.auth;
    return {
        user,
    };
}

export default connect(mapStateToProps)(Parameters);

