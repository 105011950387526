import { React ,Component } from "react"
export class Dots extends Component {
    render() {
        return (
            <svg className="dots" width="35px" height="15px" viewBox="0 0 132 58" version="1.1" xmlns="http://www.w3.org/2000/svg" 
            xmlnsXlink="http://www.w3.org/1999/xlink" xmlnssketch="http://www.bohemiancoding.com/sketch/ns">
                <title>dots</title>
                <desc>Created with Sketch.</desc>
                <defs></defs>
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" sketchtype="MSPage">
                    <g className="dots" sketchtype="MSArtboardGroup" fill="#A3A3A3">
                        <circle className="dot1" sketchtype="MSShapeGroup" cx="25" cy="30" r="13"></circle>
                        <circle className="dot2" sketchtype="MSShapeGroup" cx="65" cy="30" r="13"></circle>
                        <circle className="dot3" sketchtype="MSShapeGroup" cx="105" cy="30" r="13"></circle>
                    </g>
                </g>
            </svg>
        )
    }
}