import React, { Component } from "react";
import { connect } from "react-redux";
import CustomLinearGauge from './custom-lineargauge.component';
import NavBarComp from "./navbarcomp.component";
import { Economy } from "./economy.component";
import Avatar from "./avatar.component";
import UserService from "../services/user.service";
import ExperimentState from "./experiment-state.component";

class User extends Component {
  constructor(props) {
    super(props);
    this.currentUser = props.user;
    this.state = {
      batteryState: 0,
      imgSrc: 1,
      currentUser: props.user,
      userData: {},
      loading: true
    };
  }

  componentDidUpdate() {
    window.parent.localStorage.setItem("userState", JSON.stringify(this.state));
    window.parent.localStorage.setItem("user", JSON.stringify(this.currentUser));
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleEnterPress);
    document.removeEventListener("visibilitychange", this.handleVisibilityChange);
  }


  componentDidMount() {
    document.addEventListener("keydown", this.handleEnterPress);
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
    setTimeout(() => {
      this.synchronizeUser();
    }, 100);
  }

  synchronizeUser() {
    UserService.getUserLastConsumption(this.currentUser.username)
      .then(response => {
        let userData = response.data;
        let img = 1;
        if (userData.mean_savings >= 0 && userData.mean_savings < 1) {
          img = 2;
        } else if (userData.mean_savings >= 1 && userData.mean_savings < 2) {
          img = 3;
        } else if (userData.mean_savings >= 2 && userData.mean_savings < 4) {
          img = 4;
        } else if (userData.mean_savings >= 4 && userData.mean_savings < 6) {
          img = 5;
        } else if (userData.mean_savings >= 6 && userData.mean_savings < 8) {
          img = 6;
        } else if (userData.mean_savings >= 8) {
          img = 7;
        }
        this.currentUser.imgSrc = img
        this.currentUser.batteryState = userData.mean_savings;
        const navHistory = JSON.parse(window.parent.localStorage.getItem("navHistory")) || [];
        navHistory.push({ type: 'user', username: this.currentUser.username, 'timestamp': Date.now() });
        UserService.getExperimentState(this.currentUser.username, navHistory)
          .then(response => {
            this.currentUser.experiment_state = response.data;
            this.setState({ currentUser: this.currentUser });
            window.parent.localStorage.setItem("navHistory", JSON.stringify([]));
          })
          .catch(error => {
            this.setState({ error: error.message });
          });
        this.setState({ userData: userData });
        this.setState({ batteryState: userData.mean_savings, imgSrc: img });
      })
      .then(() => {
        this.setState({ loading: false });
        window.parent.localStorage.setItem("userState", JSON.stringify(this.state));
        window.parent.localStorage.setItem("user", JSON.stringify(this.currentUser));
      })
      .catch(error => {
        this.setState({ error: error.message, loading: false });
      });
  }

  handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      this.synchronizeUser();
    }
  }


  render() {
    const gradientStyle = { background: `linear-gradient(to top, white 28%, ${this.currentUser.avatarColor} 28%, ${this.currentUser.avatarColor} 70%, white 70%)` };
    let savingsText = this.state.userData.mean_savings >= 0 ? `Depuis le début du défi, votre économie est de ${this.state.userData.mean_savings ? this.state.userData.mean_savings : '0'}%, ce qui représente ${this.state.userData.mean_kwh_savings ? this.state.userData.mean_kwh_savings : '0'} kWh.` : `Depuis le début de l'expérience, vous n'avez pas réduit votre consommation mais elle a augmenté de ${this.state.userData.mean_savings ? -this.state.userData.mean_savings : '0'}%.`;
    return (
      <div>
        {this.state.currentUser.experiment_state === 3 && !this.state.loading && (
          <div id="energy-save-container">
            <NavBarComp page="user" username={this.currentUser.username} color={this.currentUser.avatarColor} />
            <div className="d-flex align-items-center justify-content-center" id="avatar-part">
              <CustomLinearGauge batteryState={this.state.batteryState} avatarColor={this.currentUser.avatarColor} page={'user'} />
              <Avatar imgSrc={this.state.imgSrc} color={this.currentUser.avatarColor} />
            </div>
            <Economy title={"économie d'électricité :"} result={this.state.userData.feedback ? this.state.userData.feedback : 'Pas défini'} text={savingsText} link={'classement'} gradientStyle={gradientStyle} />
            {(this.currentUser.userGroup === "2" || this.currentUser.userGroup === "4") &&
              <Economy title={"classement :"} result={`${this.state.userData.ranking ? this.state.userData.ranking : 'Pas défini'}`} text={`sur ${this.state.userData.max_ranking ? this.state.userData.max_ranking : '0'} ménages`} gradientStyle={gradientStyle} />
            }
            {(this.currentUser.userGroup === "3" || this.currentUser.userGroup === "4") &&
              <Economy title={"Bonus :"} result={`CHF ${this.state.userData.financial ? this.state.userData.financial : '0'}.-`} text="Bonus si vous gardez ce niveau d'économie jusqu'à la fin du défi" gradientStyle={gradientStyle} />
            }
            <Economy title={"Consommation :"} result={`${this.state.userData.kwh_week ? this.state.userData.kwh_week : '0'} kWh`} text="Votre consommation de la semaine" link="consommation" gradientStyle={gradientStyle} />
          </div>)}
        {this.state.loading && (<div>
          Chargement...
        </div>)}
        {((this.state.currentUser.experiment_state === 2) && !this.state.loading) && (
          <div>
            <div id="energy-save-container">
              <NavBarComp page="state2" username={this.currentUser.username} color={this.currentUser.avatarColor} />
              <ExperimentState expState={this.state.currentUser.experiment_state} />
            </div>
          </div>
        )
        }
        {((this.state.currentUser.experiment_state === 1 || this.state.currentUser.experiment_state === 4) && !this.state.loading) && (
          <div>
            <div id="energy-save-container">
              <ExperimentState expState={this.state.currentUser.experiment_state} />
            </div>
          </div>
        )
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(User);