import React from "react";

export const Economy = ({ title, result, text, link, gradientStyle }) => {
    const handleRedirect = () => {
        if(link)window.location.href = link;
    }
    return (
        <div className="economy">
            <h4 className="economy-title">{title}</h4>
            <div onClick = {handleRedirect} className={link ? "economy-detail economy-linked":"economy-detail"}>
                <div className="text-column">
                    <h1 className="economy-result" style={gradientStyle}>{result}</h1>
                    <div className="economy-text">{text}</div>
                </div>
                {link && (
                    <div className="image-column">
                        <a className="economy-link"><img src='./interface/arrow.svg' /></a>
                    </div>
                )}
            </div>
        </div>
    )
}