import { React, Component } from 'react';
import { connect } from "react-redux";
import UserService from '../services/user.service';

class Deconnexion extends Component {
    constructor(props) {
        super(props);
        this.currentUser = props.user;
        this.state = {
            disconnect: false
        }
    }

    componentDidMount() {
        UserService.deconnexion(this.currentUser.username)
            .then(response => {
                window.parent.localStorage.removeItem("user");
                window.parent.localStorage.removeItem("userState");
                window.parent.localStorage.removeItem("navHistory");
                window.location.href = "/";
            }).catch(error => {
                console.log(error);
            });

    }


    render() {
        return (
            <div className="row">
                Déconnexion en cours...
            </div>
        );
    }

}
function mapStateToProps(state) {
    const { user } = state.auth;
    return {
        user,
    };
}

export default connect(mapStateToProps)(Deconnexion);

