import React from "react";

/**
 * Composant qui gère les messages de l'utilisateur et du chatbot.
 * @param {string} content - Le contenu du message à afficher. 
 * @param {boolean} isQuestion - Indique si le message est une question ou une réponse.
 * @returns {JSX.Element} - Le composant d'affichage d'un message.
 */
const ChatMessage = ({ content, isQuestion, isChatBot, avatarColor }) => {
  const backgroundColor = isQuestion ? avatarColor : "white";
  return (
    <div className={`chat-message ${isQuestion ? "question" : "answer"} ${isChatBot?'':'noChat'}`} style={{backgroundColor : backgroundColor}}>
      {content}
    </div>
  );
};

export default ChatMessage;
